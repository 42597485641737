<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{
        requestId
          ? t("headings.create_project_for")
          : t("headings.create_project")
      }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements.length" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="createProject"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const requestId = ref(route.query?.requestId);

    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => store.state.estate.waiting);

    const statuses = ref([
      { name: "todo", value: "todo" },
      { name: "doing", value: "doing" },
      { name: "done", value: "done" },
    ]);

    const formElements = ref([]);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: "",
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "estate",
          label: "estate_name",
          optionLabel: ["name"],
          options: estates.value,
          rules: [],
          value: "",
          variable: "estateId",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "select",
          id: "status",
          label: "status",
          optionLabel: ["name"],
          options: statuses,
          rules: ["isRequired"],
          value: "",
          variable: "status",
          translatableLabel: "status",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const createProject = (formData) => {
      const data = {
        companyId: user.value.companyId,
        createdAt: new Date().toISOString().split("T")[0],
        estateId: formData.estateId,
        name: formData.name,
        requestId: requestId.value ? requestId.value : null,
        status: formData.status,
      };
      store.dispatch("project/createProject", data);
    };

    onMounted(() => {
      store.dispatch("estate/getEstates", user.value.companyId);
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      createProject,
      formElements,
      requestId,
      t,
    };
  },
};
</script>
